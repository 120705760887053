import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-023fb63a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "agreements" }
const _hoisted_2 = { class: "agreements__box" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "agreements__content" }
const _hoisted_5 = { class: "agreements__checkbox" }
const _hoisted_6 = { class: "agreements__checkbox--label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_regulation_text = _resolveComponent("regulation-text")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", {
        innerHTML: _ctx.t('AGREEMENTS.HEADER')
      }, null, 8 /* PROPS */, _hoisted_3),
      _createVNode(_component_a_divider),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_regulation_text)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_a_checkbox, {
          checked: _ctx.checked,
          "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t("AGREEMENTS.AGREE")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["checked"])
      ]),
      _createVNode(_component_a_button, {
        class: "agreements__button",
        type: "primary",
        "html-type": "submit",
        onClick: _ctx.acceptAgreements,
        loading: _ctx.processingToTheNextPage,
        disabled: !_ctx.checked
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("AGREEMENTS.GO")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick", "loading", "disabled"])
    ])
  ]))
}